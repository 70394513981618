<template>
    <div class="mobileScreen-main-container">
      <MobileHeader />
      <div
        class="mobileScreen-main-innerContainer d-flex align-items-center justify-content-center flex-column"
      >
        <div class="mobilescreen-completed">
          Completed Successfully
        </div>
        <div
          class="mt-4 text-center mobileScreen-TD-otherInfo-subhead"
          style="width: 93%;"
        >
          You have successfully completed this test.
        </div>
        <div class="mt-4">
          <img src="../../assets/mobileScreenIcons/completed-circle.svg" alt="" />
        </div>
        <div
          @click="openWebsite"
          class="mt-5 mobileScreen-CT-testAgain-text pointer"
        >
          Okay
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MobileHeader from "./MobileHeader.vue";
  import { mapGetters } from "vuex";
  export default {
    name:"MTclosed",
    components: {
      MobileHeader,
    },
    computed: {
      ...mapGetters("sectionVUEX", {
        testDetails: "getTestDetails",
      }),
    },
    methods: {
      openWebsite() {
        try {
            localStorage.clear();
            location.replace("https://www.hire3x.com/");
        } catch (error) {
          location.replace("https://www.hire3x.com/");
          console.log(error);
        }
      },
      testFinsihed() {
        this.$router.push({ path: "/mlogin" });
      },
    },
  };
  </script>
  